<template>
    <v-form ref="personalInfoForm" lazy-validation>
        <v-timeline
                class="user_add_form"
                align-top
                dense>
            <v-timeline-item
                    color="red"
                    small>
                <v-layout pt-3>
                    <v-flex>
                        <strong>Password Update
                            <span style="float: left;padding-right: 5px;">
                                <v-icon v-if="['warning','error'].includes(passwordColor)" :color="passwordColor">info
                                </v-icon>
                                <v-icon v-if="['success'].includes(passwordColor)" :color="passwordColor">check_circle
                                </v-icon>
                            </span>
                        </strong>
                        <div class="caption" style="color: #888;">Treat your password like your toothbrush. Don't let anybody else use it, and get a new one every six months.</div>
                        <v-flex xs11>
                            <v-flex pt-3 xs12>
                                <v-container style="padding:0" grid-list-md>
                                    <v-layout row wrap>
                                        <v-flex sm4 xs12>
                                            <small class="input-label">Old Password.</small>
                                            <v-text-field solo
                                                          autocomplete="off"
                                                          type="password"
                                                          label="Old Password*" required class="pa-0"
                                                          v-model="form.old_password"
                                                          :error-messages="form.errors.get('old_password')"
                                                          name="name"/>
                                            <!--<h4>{{user.fname || '-'}}</h4>-->
                                        </v-flex>
                                        <v-flex sm4 xs12>
                                            <small class="input-label">New Password.</small>
                                            <v-text-field solo
                                                          autocomplete="off"
                                                          label="New Password*"
                                                          type="password"
                                                          required
                                                          hint="Atleat 8 characters"
                                                          class="pa-0"
                                                          v-model="form.password"
                                                          :error-messages="form.errors.get('password')"
                                                          name="name"
                                            />
                                        </v-flex>

                                        <v-flex sm4 xs12>
                                            <small class="input-label">Password Confirmation.</small>
                                            <v-text-field solo
                                                          autocomplete="off"
                                                          type="password"
                                                          label="Confirm Password*" required class="pa-0"
                                                          v-model="form.password_confirmation"
                                                          :error-messages="form.errors.get('password_confirmation')"
                                                          name="name"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-flex>
                        </v-flex>
                    </v-flex>
                </v-layout>

                <v-card-actions style="padding-bottom: 80px;">
                    <v-btn @click="$emit('onCancel')" color="warning" outline>
                        <v-icon dark left>arrow_back</v-icon>
                        Cancel
                    </v-btn>

                    <v-btn color="success" outline @click="updateMyPassword">Update Password</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

            </v-timeline-item>
        </v-timeline>
    </v-form>
</template>

<script>
    import Form from "../../../../../library/Form";

    export default {
        props: {
            user: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                userUpdate: {},
                form: new Form({
                    old_password: '',
                    password: '',
                    password_confirmation: ''
                }, '/auth/me/update-password'),
                passwordColor: ''
            }
        },
        watch: {
            'form.password': {
                handler(name) {
                    this.strengthChecker(name);
                    // this.updateUserForm.mname = this.capitalizeString(name)
                }
            }
        },
        mounted() {
            // this.userUpdate = this.user;
            // this.updateUserForm.edit(this.user);
            // this.getStates();
        },
        methods: {
            updateMyPassword() {
                // console.log(this.userUpdate);
                // this.updateUserForm.fireFetch = false;
                // this.updateUserForm.endpoint = this.updateUserForm.endpoint;
                // console.log(this.updateUserForm.data());

                this.$rest.put('/auth/me/update-password', this.form.data()).then(res => {
                    this.form.success(res.data);
                    // console.log(res);
                    this.$events.fire('notification', {message: res.data.message, status: 'success'});
                    this.$emit('onUpdate');
                }).catch(err => {
                    this.$events.fire('notification', {message: err.response.data.message, status: 'error'})
                    this.form.failure(err.response.data);
                })
                // this.form.store().then(res => {
                //     this.$emit('onUpdate')
                // });
            },
            strengthChecker(password) {
                // var strength = document.getElementById(‘strength’);
                let strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
                let mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
                let enoughRegex = new RegExp("(?=.{6,}).*", "g");
                if (false == enoughRegex.test(password)) {
                    this.passwordColor = 'error';
                } else if (strongRegex.test(password)) {
                    this.passwordColor = 'success';
                } else if (mediumRegex.test(password)) {
                    this.passwordColor = 'warning';
                } else {
                    this.passwordColor = 'error';
                }
            }

        }
    }
</script>